import { BookFilled, BookOutlined, CloseOutlined, DeleteFilled, DownSquareFilled, SlidersOutlined, StarFilled } from '@ant-design/icons';
import { Alert, Badge, Button, Divider, Image, Popover, Space, Tag, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { apiDownload } from '../../utils/Api';
import conditional from "../../utils/conditional";
import Html from "../../utils/Html";
import { addCommas, disabledColor, parseAuthors, removeNulls } from "../../utils/Utils";
import Bookmark from "../Bookmark";
import BusinessContactInfo from "../BusinessContactInfo";
import ItemLayouts from "../ItemLayouts";
import Overlay from "../Overlay";
import Peek from "../Peek";
import Renderer from "../Renderer";
import Slider from "../Slider";
import SliderItem from "../SliderItem";
import TitleResults from "../title/TitleResults";
import ListArchive from "./ListArchive";
import ListCreateExcel from "./ListCreateExcel";
import ListCreatePdf from "./ListCreatePdf";
import ListDelete from "./ListDelete";
import ListEdit from "./ListEdit";
import ListFav from "./ListFav";
import ListFolder from "./ListFolder";
import ListNotes from "./ListNotes";
import ListsCopy from "./ListsCopy";
import ListShare from "./ListShare";
import ListTrash from "./ListTrash";

export default function ListItem(props) {
    const {
        is_catalogue = false,
        drawComponent = () => { },
        results = {},
        setResults = () => { },
        data = {}
    } = props;
    const history = useHistory();
    const {
        list_id = "",
        actual_id = 0,
        is_dynamic = false,
        owner_name = "",
        user_name = "",
        category = "",
        in_list = false,
        subcategory = "",
        season = "",
        vendor = { name: "", acct: "", id: "" },
        agency = { name: "", acct: "", id: "" },
        subject = "",
        note = "",
        note_posted = "",
        name = "",
        date_posted = null,
        date_updated = null,
        date_created = null,
        views = null,
        description = "",
        folder = "",
        is_starred = false,
        is_archived = false,
        is_deleted = false,
        onhand = null,
        onorder = null,
        sequence = null,
        isbns = [],
        isbns_count = 0,
        site_content = false,
        bookmark_data = null,
        has_linked_pdf = false,
    } = removeNulls(data);


    const linkOut = (_id) => {
        window.open("/purchasing/browse/filter/x/" + _id + "/v/sequence", "_blank");
    }


    function dateFormat(date) {
        //broke this out to make the return a little cleaner, and it's reusable
        return moment(date * 1000).format("MMMM D YYYY h:mma");
    }


    const ListImage = (props) => {
        const { img = {} } = props;

        const [imgError, setImageError] = useState(false);
        return (
            <>
                {(imgError && <>
                    <div style={{ "height": "150px", "width": "110px" }} className="card-fallback-small">
                        <div style={{ "fontSize": "12px" }}><strong>{img.title}</strong></div>
                        <div className="shim" /><div className="shim" />
                        <small><em>{parseAuthors(img.authors, "#000", "12px")}</em></small>
                    </div>
                </>)}
                {(!imgError && <>
                    <img className="shadow-large" style={drawImage(img.highlight)} onError={(e) => setImageError(true)} src={"https://cdn1.bookmanager.com/i/slider_23.php?b=" + img.eisbn + "&b2b=" + img.cover_image_key + "&cb=" + img.cover_image_cache} />
                </>)}

            </>

        )
    }

    const downloadPDF = () => {
        apiDownload({ list_id: list_id }, "titlelist/getLinkedPdf")
    }

    function drawCardBorder(highlight) {
        if (highlight) {
            return {
                top: "-15px",
                lineHeight: "12px",
                padding: "1px 2px",
                backgroundColor: "#ff0099",
            }
        }
        return {
            top: "-15px",
            lineHeight: "12px",
            padding: "1px 2px",
        }
    }

    function badgeText() {
        return "Highlighted"
    }

    function drawImage(highlight) {
        if (highlight) {
            return {
                borderStyle: "solid",
                borderColor: "#ff0099",
                borderWidth: "3px",
                maxHeight: "150px",
            }
        }
        return {
            maxHeight: "150px",
        }
    }

    return (
        <>
            <div className="shim" /><div className="shim" />
            <div className="hideHover">
                <div className="float-flex">
                    <div>
                        <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                            <conditional.true value={has_linked_pdf}>
                                <Image
                                    preview={false}
                                    style={{ "cursor": "pointer", "marginRight": "10px", "marginTop": "7px" }}
                                    width="30px"
                                    height="30px"
                                    src="https://bookmanager.com/i/pdf.png"
                                    onClick={() => downloadPDF()}
                                />
                            </conditional.true>
                            <a onClick={() => linkOut(list_id)}>
                                {name} <small style={{ "fontWeight": "normal" }} className="c" > - {addCommas(isbns_count)} titles  {(is_dynamic && <><small><em>(Dynamic)</em></small></>)}</small>
                            </a>
                            <br clear="both" />
                            <span className="c" style={{ "fontSize": "13px", "display": "block", "marginTop": "5px" }}>
                                <Overlay width={700} component={<div style={{ "padding": "20px" }}><strong>Contact</strong><Divider dashed style={{ "margin": "10px 0px" }} /><div className="shim" /><div style={{ "margin": "-8px" }}><BusinessContactInfo id={vendor.id} /></div></div>}><a style={{ "textAlign": "left", "maxWidth": "250px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>{vendor.name}</a></Overlay>
                            </span>
                            <span className="c" style={{ "fontSize": "13px", "display": "block" }}>
                                <Overlay width={700} component={<div style={{ "padding": "20px" }}><strong>Contact</strong><Divider dashed style={{ "margin": "10px 0px" }} /><div className="shim" /><div style={{ "margin": "-8px" }}><BusinessContactInfo id={agency.id} /></div></div>}><a style={{ "textAlign": "left", "maxWidth": "250px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>{(agency.name) ? <>REP: {agency.name}</> : <></>}</a></Overlay>
                            </span>
                            <Space>
                                {(category && <span style={{ "color": disabledColor, "fontSize": "12px", "display": "block" }}><em><span style={{ "fontWeight": "normal" }}>Category:</span> {category} &nbsp;</em></span>)}
                                {(season && <span style={{ "color": disabledColor, "fontSize": "12px", "display": "block" }}><em><span style={{ "fontWeight": "normal" }}>Season: </span>{season} &nbsp;</em></span>)}
                                <span style={{ "color": disabledColor, "fontSize": "12px", "display": "block", "fontWeight": "normal" }}><em>List: {actual_id} </em>&nbsp;</span>
                                {(folder && <span style={{ "color": disabledColor, "fontSize": "12px", "display": "block", "fontWeight": "normal" }}><em>Folder:<ListFolder type="link" getLists={props.getLists} folder={folder} list_id={list_id} isbns={isbns} >{folder} </ListFolder></em>&nbsp;</span>)}
                            </Space>
                            <div className="shim" />
                        </Typography.Title>
                    </div>
                    <div>
                        <div style={{ "float": "right", "textAlign": "right" }}>
                            <Typography.Paragraph style={{ "fontSize": "12px" }}>
                                <Space size={0}>
                                    <Bookmark list_id={list_id} link />
                                    <ListFav getLists={props.getLists} list={data} results={results} setResults={setResults} />
                                    <ListArchive list={data} results={results} setResults={setResults} />
                                    <ListTrash list={data} results={results} setResults={setResults} />
                                    {(is_catalogue && <ListFolder getLists={props.getLists} folder={folder} list_id={list_id} isbns={isbns} icon={true} ></ListFolder>)}

                                </Space>
                            </Typography.Paragraph>
                            <Typography.Paragraph style={{ "fontSize": "12px" }}>
                                <span>Updated: </span><span style={{ "fontWeight": "normal" }}>{dateFormat(date_updated)}</span><br />
                                <conditional.true value={(date_posted)}>
                                    <span style={{ "color": disabledColor, "fontSize": "12px", "display": "block", "fontWeight": "normal" }}><em>Posted: {dateFormat(date_posted)}</em></span>
                                </conditional.true>
                                {(owner_name && <span style={{ "color": disabledColor, "fontSize": "12px", "display": "block", "fontWeight": "normal" }}><em>Managed by: {owner_name} &nbsp;</em></span>)}
                                {(user_name && <span style={{ "color": disabledColor, "fontSize": "12px", "display": "block", "fontWeight": "normal" }}><em>Updated by: {user_name} &nbsp;</em></span>)}
                            </Typography.Paragraph>
                        </div>
                    </div>
                </div>

                <div className="shim"></div>
                {/* Max 11 wide */}
                <div style={{ "maxHeight": "170px", "width": "1196px", "paddingBottom": "10px" }}>
                    <Slider>
                        <Space>
                            <SliderItem action={() => linkOut(list_id)} key={"498395"}>
                                <img className="shadow-large inner-dashed-border" style={{ "maxHeight": "150px" }} onError={(e) => e.target.style.display = 'none'} src={"https://cdn1.bookmanager.com/tl_img?def_id=" + actual_id} />
                            </SliderItem>
                            {isbns.slice(0, 10).map(img => {
                                return (
                                    <>
                                        <conditional.true value={img.highlight}>
                                            <div style={drawCardBorder(img.highlight)}>
                                                <small className="c" style={img.highlight ? { "color": "#FFFFFF" } : null}><strong>{img.highlight ? "Highlighted" : ""}</strong></small>
                                            </div>
                                            <SliderItem key={img.isbn} isbn={img.isbn} action={() => drawComponent(
                                                <div key={img.isbn}>
                                                    <div style={{ "padding": "0 0px" }}>
                                                        <div style={{ "marginTop": "0px", "borderTop": "1px solid" }} className="closebar bc">
                                                            <Button style={{ "float": "right" }} onClick={() => drawComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                                                            <br clear="both" />
                                                        </div>
                                                    </div>
                                                    <div className="shim" /><div className="shim" />
                                                    <Renderer><ItemLayouts display="details2" isbn={img.isbn} /></Renderer>
                                                    <div className="shim" /><div className="shim" />
                                                </div>)}>
                                                <img className="shadow-large" style={drawImage(false)} onError={(e) => e.target.src = "/no-cover.png"} src={"https://bookmanager.com/i/slider_im.php?b=" + img.eisbn} />
                                            </SliderItem>
                                        </conditional.true>

                                        <conditional.true value={!img.highlight}>
                                            <SliderItem key={img.isbn} isbn={img.isbn} action={() => drawComponent(<div style={{ "cursor": "pointer" }} key={img.isbn}>
                                                <div style={{ "padding": "0 0px" }}>
                                                    <div style={{ "marginTop": "0px", "borderTop": "1px solid" }} className="closebar bc">
                                                        <Button style={{ "float": "right" }} onClick={() => drawComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                                                        <br clear="both" />
                                                    </div>
                                                </div>
                                                <div className="shim" /><div className="shim" />

                                                <Renderer><ItemLayouts actual_id={actual_id} display="details2" isbn={img.isbn} /></Renderer>
                                                <div className="shim" /><div className="shim" />
                                            </div>)}>
                                                <ListImage img={img} />
                                            </SliderItem>
                                        </conditional.true>
                                    </>

                                )
                            })}
                            {((isbns_count > 7) && <div onClick={() => linkOut(list_id)} style={{ "textAlign": "center", "width": "100px" }}><small><a>View all <br />{isbns_count} titles</a></small></div>)}
                        </Space>
                    </Slider>
                </div>
            </div>

            <conditional.true value={(description && !site_content)}>
                <div><div className="shim" /><div className="shim" /><span style={{ "color": disabledColor, "fontSize": "12px", "display": "block", "fontWeight": "normal" }}><Html html={description} /></span><div className="shim" /></div>
            </conditional.true>
            <conditional.true value={(isbns.length > 0)}>
                <div style={{ "float": "right" }}>
                    <Button onClick={() => linkOut(list_id)} size="small"><small>View all {addCommas(isbns_count)} titles</small></Button>
                </div>
            </conditional.true>

            <conditional.true value={(note)}>
                <div style={{ "maxWidth": "500px" }}>
                    <Alert style={{ "padding": "5px 10px" }} description={<div style={{ "marginTop": "-10px" }}><small>{note}</small></div>} message={<div><small><div style={{ "float": "right" }}>{(note_posted) ? <small>{moment(note_posted * 1000).format("MMM D, YYYY")}</small> : ""}</div> Private notes:</small></div>} type="warning" />
                    <br />
                </div>
            </conditional.true>

            <Space size={0}>
                {((!is_catalogue) && <Tag className="tag-btn cataloguebtn" color={disabledColor}><Overlay maskClosable={false} width={700} component={<ListEdit results={results} setResults={setResults} data={data} />}>Edit</Overlay></Tag>)}
                {(!is_dynamic && <Tag className="tag-btn cataloguebtn" color={disabledColor}><Overlay width={450} component={<ListsCopy results={results} setResults={setResults} list_id={list_id} name={name} />}>Copy</Overlay></Tag>)}
                {((!is_catalogue) && <ListDelete name={name} results={results} setResults={setResults} list_id={list_id} ><Tag className="tag-btn cataloguebtn" color={disabledColor}>Delete</Tag></ListDelete>)}
                <Tag className="tag-btn cataloguebtn" color={disabledColor}><Overlay width={450} component={<ListNotes results={results} setResults={setResults} note={note} list_id={list_id} />}>My Notes</Overlay></Tag>
                {/* <Tag className="tag-btn" color={disabledColor}>Email</Tag> */}
                <Tag className="tag-btn cataloguebtn" color={disabledColor}><Overlay component={<ListCreateExcel list_id={list_id} />} width={500}>Excel</Overlay></Tag>
                <Tag className="tag-btn cataloguebtn" color={disabledColor}><Overlay component={<ListCreatePdf is_catalogue={is_catalogue} count={isbns_count} list_id={list_id} />} width={500}>PDF</Overlay></Tag>
                <Tag onClick={() => window.open("/print/list/" + list_id, "_blank")} className="tag-btn cataloguebtn" color={disabledColor}>Print</Tag>
                <Tag className="tag-btn cataloguebtn" color={disabledColor}><Overlay width={520} component={<ListShare list_id={list_id} />}>Share</Overlay></Tag>
            </Space>

        </>
    );
}